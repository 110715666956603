<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:54:24
-->
<template>
  <a-layout-header
    style="background: #fff; padding: 0; top: 0"
    :style="{ position: 'staic', zIndex: 100, width: '100%' }"
  >
    <a-row type="flex" justify="space-between" align="middle">
      <a-col :span="4">
        <MenuUnfoldOutlined
          v-if="collapsed"
          class="trigger them-header-trigger"
          @click="changeCollapsed"
        />
        <MenuFoldOutlined
          v-else
          class="trigger them-header-trigger"
          @click="changeCollapsed"
        />
      </a-col>
      <a-col
        :span="5"
        class="margin-right"
        style="width: 100%; display: flex; align-items: center; justify-content: flex-end"
      >
        <!-- <a-badge count="0" show-zero>
          <a-button type="danger" shape="circle">
            <template #icon>
              <BellOutlined />
            </template>
          </a-button>
        </a-badge> -->
        <a-dropdown >
          <a-button type="primary" @click="handlechangeTheme">
            <template #icon>
              <Wicon icon="FontColorsOutlined" />
            </template>
          </a-button>

          <template #overlay>
            <a-menu @click="handlechangeTheme">
              <a-menu-item key="Simplicity">简约主题</a-menu-item>
              <a-menu-item key="Gilding">鎏金主题</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown v-model:visible="dropdownVisible">
          <div class="margin-left-lg" style="padding-bottom: 5px">
            <a @click.prevent>
              <a-avatar>U</a-avatar>
            </a>
          </div>
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item key="loginOut">退出登陆</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script  >
import { defineComponent, inject, ref } from "vue";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import { collapsed, changeCollapsed } from "../useLayoutStore";
import { useRouter } from "vue-router";
import { delValue } from "@/common/util/storage";
import { notification, Modal } from "ant-design-vue";
import { changeTheme,currentTheme } from "@/common/config/theme.config";

export default defineComponent({
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  setup() {
    const { field } = inject("globalConfig");

    const dropdownVisible = ref(false);

    const router = useRouter();

    const handleMenuClick = (e) => {
      if (e.key === "loginOut") {
        Modal.confirm({
          title: "确定要退出登陆吗?",
          okText: "退出",
          okType: "danger",
          cancelText: "取消",
          onOk: async () => {
            dropdownVisible.value = false;
            delValue(field.info);
            delValue(field.token);
            notification.success({
              message: "退出登陆成功！",
              duration: 2,
            });
            router.replace({ name: "login" });
          },
        });
      }
    };

    // 主题切换函数
    const handlechangeTheme = ({ key }) => {
      if(currentTheme === key) return;
      console.log("key",key);
      changeTheme(key);
    };

    return {
      handlechangeTheme,
      collapsed,
      handleMenuClick,
      dropdownVisible,
      changeCollapsed,
    };
  },
});
</script>

<style lang="less" scoped>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

  &::hover {
    color: #1890ff;
  }
}
</style>
