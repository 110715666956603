/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:51:59
 */

import { isPlainObject, forIn } from "lodash";

export let currentTheme = "Simplicity";

export const themes = [
  {
    name: "鎏金主题",
    id: "Gilding",
    colors: {
      "w-bg-color": "#1d1e23",
      "w-contrast-color": "#f6ca9d",
      "w-text-color": "#f6ca9d",
      "w-text-contrast-color": "#fff",
      "w-text-color-secondary": "#666",
      "w-side-color": "#1d1e23",
      "w-body-color": "#333",
      "w-side-item-color": "#000",
    },
  },
  {
    name: "简约主题",
    id: "Simplicity",
    colors: {
      "w-bg-color": "#fff",
      "w-contrast-color": "#1890ff",
      "w-text-color": "#000",
      "w-text-contrast-color": "#000",
      "w-text-color-secondary": "#666",
      "w-side-color": "#fff",
      "w-body-color": "#f1f1f1",
      "w-side-item-color": "#e6f7ff",
    },
  },
];

export const changeTheme = (themeId) => {
  const themeOption = themes.filter((item) => item.id === themeId)[0];
  if (isPlainObject(themeOption)) {
    currentTheme = themeId;
    const bodyStyle = document.getElementsByTagName("body")[0].style;
    forIn(themeOption.colors, (value, key) => {
      bodyStyle.setProperty(`--${key}`, value);
    });
  }
};
